import { ThemeProvider } from '@emotion/react';
import { LoadingButton } from '@mui/lab';
import {
  StyledEngineProvider,
  GlobalStyles,
  Stack,
  Box,
  Typography,
} from '@mui/material';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { images } from './assets/images';
import { theme } from './common/theme';

import './logged-in.css';
import QRForm from './components_v2/logged_in/QRForm';
import { useAppDispatch, useAppSelector } from './redux/store';
import { nextQuestion } from './redux/onboarding';
import BmiForm from './onboarding/components/onboarding/BMIForm';
import CompleteForm from './onboarding/components/onboarding/CompleteForm';
import GoalForm from './onboarding/components/onboarding/GoalForm';
import HeightForm from './onboarding/components/onboarding/HeightForm';
import LQIForm from './onboarding/components/onboarding/LQiForm';
import QuestionForm from './onboarding/components/onboarding/QuestionForm';
import QuestionSliderForm from './onboarding/components/onboarding/QuestionSliderForm';
import WeightForm from './onboarding/components/onboarding/WeightForm';
import {
  OnboardingContinueRef,
  OnboardingQuestion,
} from './redux/onboardingTypes';
import SYDLeftPanelContainer from './components_v2/SYDLeftPanelContainer';
import GridFormV2 from './components_v2/questions/GridFormV2';
import { useSearchParams } from 'react-router-dom';
import LQIVideoView from './onboarding/components/onboarding/LQIVideoView';
import BottomQR from './components_v2/logged_in/BottomQR';
import { useSYDMessages } from './common/v2/hooks';
import LoadingMessagesContainer from './components_v2/forms/LoadingMessagesContainer';
import { useTranslation } from 'react-i18next';
import { useAppTransitions } from './ui';
import HeaderProgressBar from './components_v2/header/HeaderPogressBar';

export default function LoggedInV2() {
  const { t } = useTranslation();

  const ref = useRef<OnboardingContinueRef>(null);
  const leftPanelImageRef = useRef<HTMLImageElement>(null);

  const questions = useAppSelector((state) => state.onboarding.questions);
  const loadingQuestion = useAppSelector(
    (state) => state.onboarding.loadingQuestion
  );

  const buttonEnabled = useAppSelector(
    (state) => state.onboarding.buttonEnabled
  );

  const { leftPanelTranslationX } = useAppTransitions(leftPanelImageRef, false);

  const dispatch = useAppDispatch();

  const [loadingFirstMessages, setLoadingFirstMessages] = useState(false);

  const currentQuestion = useMemo(() => {
    if (questions.length === 0) {
      return null;
    } else {
      return questions[questions.length - 1];
    }
  }, [questions]);

  const showButton = useMemo(() => {
    if (!currentQuestion) {
      return true;
    }

    return !currentQuestion.is_lqi_dce && !currentQuestion.finished;
  }, [currentQuestion]);

  const lqiHeader = useMemo(() => {
    if (
      !currentQuestion ||
      !currentQuestion.status ||
      currentQuestion.finished
    ) {
      return null;
    }

    return (
      <Stack direction="row" alignItems="center" gap="8px" className="m-10">
        <Typography className="font-semibold text-[16px] text-[#292D3233]">
          {currentQuestion.status.text}
        </Typography>

        <video
          src={images.v2.onboarding.lqiHeaderVideoMobile}
          autoPlay={true}
          loop={true}
          controls={false}
          muted={true}
          playsInline={true}
          className="h-[80px]"
        />
      </Stack>
    );
  }, [currentQuestion]);

  const renderQuestion = useCallback(
    (question: OnboardingQuestion) => {
      if (question.finished) {
        return <CompleteForm />;
      } else if (question.is_info) {
        return (
          <BmiForm
            ref={ref}
            bmi={question.answers[0].text}
            info={question.text_info}
          />
        );
      } else if (question.is_lqi_calc) {
        return <LQIVideoView />;
      } else if (question.is_height) {
        return (
          <LoadingMessagesContainer loading={loadingQuestion}>
            <HeightForm question={question} ref={ref} />
          </LoadingMessagesContainer>
        );
      } else if (question.is_weight) {
        return (
          <LoadingMessagesContainer>
            <WeightForm question={question} ref={ref} />
          </LoadingMessagesContainer>
        );
      } else if (question.is_goal) {
        return (
          <LoadingMessagesContainer loading={loadingQuestion}>
            <GoalForm question={question} ref={ref} />
          </LoadingMessagesContainer>
        );
      } else if (question.is_interests) {
        return (
          <LoadingMessagesContainer loading={loadingQuestion}>
            <GridFormV2
              ref={ref}
              question={question}
              onCustomerAnswerEntered={() => Promise.resolve(true)}
            />
          </LoadingMessagesContainer>
        );
      } else if (question.is_diets) {
        return (
          <LoadingMessagesContainer loading={loadingQuestion}>
            <GridFormV2
              ref={ref}
              question={question}
              onCustomerAnswerEntered={() => Promise.resolve(true)}
            />
          </LoadingMessagesContainer>
        );
      } else if (question.is_allegens) {
        return (
          <LoadingMessagesContainer loading={loadingQuestion}>
            <GridFormV2
              ref={ref}
              question={question}
              onCustomerAnswerEntered={() => Promise.resolve(true)}
            />
          </LoadingMessagesContainer>
        );
      } else if (question.is_lqi_dce) {
        return (
          <LoadingMessagesContainer loading={loadingQuestion}>
            <LQIForm question={question} />
          </LoadingMessagesContainer>
        );
      } else if (question.is_categorical) {
        return (
          <LoadingMessagesContainer loading={loadingQuestion}>
            <QuestionForm question={question} ref={ref} />
          </LoadingMessagesContainer>
        );
      } else {
        return (
          <LoadingMessagesContainer loading={loadingQuestion}>
            <QuestionSliderForm question={question} ref={ref} />
          </LoadingMessagesContainer>
        );
      }
    },
    [loadingQuestion]
  );

  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    setSearchParams(
      searchParams.get('lng') !== null ? `lng=${searchParams.get('lng')}` : ''
    );
  }, [searchParams, setSearchParams]);

  const { showMessages } = useSYDMessages();

  return (
    <StyledEngineProvider injectFirst={true}>
      <ThemeProvider theme={theme}>
        <GlobalStyles
          styles={{ body: { background: '#F5F6F6', padding: 0, margin: 0 } }}
        />

        <Stack
          className={`root-container ${
            currentQuestion?.finished ? 'black' : ''
          }`}
        >
          {currentQuestion && !currentQuestion.finished && (
            <HeaderProgressBar
              label={t('details')}
              step={currentQuestion.question_idx}
              totalSteps={currentQuestion.questions_total}
            />
          )}

          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <Box
              component="img"
              src={images.header.sydLogo}
              className="logo w-76 h-76 m-10"
            />

            {currentQuestion && lqiHeader}
          </Stack>

          <Stack
            direction="row"
            className={`w-full flex-1 h-0 relative transition-opacity ${
              leftPanelTranslationX !== 0 ? 'opacity-100' : 'opacity-0'
            }`}
          >
            <Stack direction="row" className="w-full" alignSelf="center">
              <Stack
                direction="row"
                flexGrow={1}
                className="w-0"
                alignSelf="center"
                justifyContent="center"
                sx={{ transform: `translateX(${leftPanelTranslationX}px)` }}
              >
                <SYDLeftPanelContainer imageRef={leftPanelImageRef} />
              </Stack>

              <Stack
                direction="row"
                justifyContent="flex-end"
                className="absolute left-0 top-0 right-0 bottom-0"
              >
                <Stack
                  direction="row"
                  flexGrow={0.5}
                  justifyContent="center"
                  alignItems="center"
                  alignSelf="center"
                  className="w-0 mt-[80px]"
                >
                  {currentQuestion ? (
                    renderQuestion(currentQuestion)
                  ) : (
                    <LoadingMessagesContainer loading={loadingFirstMessages}>
                      <QRForm />
                    </LoadingMessagesContainer>
                  )}
                </Stack>
              </Stack>
            </Stack>
          </Stack>

          {!currentQuestion || !currentQuestion.finished ? (
            <LoadingButton
              variant="contained"
              endIcon={<Box component="img" src={images.v2.button.tick} />}
              className={`button my-10 bg-text ${
                showButton ? '' : 'opacity-0'
              }`}
              loading={loadingQuestion}
              disabled={!showButton || !buttonEnabled}
              onClick={async () => {
                if (questions.length === 0) {
                  setLoadingFirstMessages(true);
                  await showMessages(
                    [t('iPreferItHere'), t('makesSense')],
                    0,
                    true
                  );
                }

                if (ref.current) {
                  ref.current.onContinuePressed();
                } else {
                  dispatch(nextQuestion());
                }
              }}
            >
              {questions.length === 0 ? t('continueHere') : t('confirm')}
            </LoadingButton>
          ) : (
            <Box className="h-[48px] my-10" />
          )}

          {currentQuestion?.finished && <BottomQR />}
        </Stack>
      </ThemeProvider>
    </StyledEngineProvider>
  );
}
